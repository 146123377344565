import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo";

export default function Impressum() {
    return (
        <>
            <Layout>
                <Seo title="Impressum" />
                <div className="bg-white">
            <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-20 lg:px-8">
                <div className="lg:grid lg:grid-cols-3 lg:gap-8">
                    <div>
                        <h1 className="text-3xl font-extrabold text-gray-900">Impressum</h1>

                    </div>
                    <div className="mt-12 lg:mt-0 lg:col-span-2">
                        <h2 className="text-lg leading-6 font-medium text-gray-900">Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
                        <p className="mt-2 text-base text-gray-500">Dirk Illenberger<br />
                            di - IT Consulting<br />
                            Bismarckstr. 24<br />
                            61169 Friedberg</p>

                        <h2 className="mt-6 text-lg leading-6 font-medium text-gray-900">Kontakt</h2>
                        <p className="mt-2 text-base text-gray-500">Telefon: +49 (0) 172 6554594<br />
                            Telefax: +49 (0) 6031 7727646<br />
                            E-Mail: dirk@illenberger.de</p>

                        <h2 className="mt-6 text-lg leading-6 font-medium text-gray-900">Umsatzsteuer-ID</h2>
                        <p className="mt-2 text-base text-gray-500">Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a Umsatzsteuergesetz:<br />
                            DE188674900</p>

                        <h2 className="mt-6 text-lg leading-6 font-medium text-gray-900">Angaben zur Berufs&shy;haftpflicht&shy;versicherung</h2>
                        <p className="mt-2 text-base text-gray-500"><strong>Name und Sitz des Versicherers:</strong><br />
                            exali GmbH<br />
                            Franz-Kobinger-Str. 9<br />
                            86157 Augsburg</p>
                        <p className="mt-2 text-base text-gray-500"><strong>Geltungsraum der Versicherung:</strong><br />Deutschland</p>

                        <h2 className="mt-6 text-lg leading-6 font-medium text-gray-900">EU-Streitschlichtung</h2>
                        <p className="mt-2 text-base text-gray-500">Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>.<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>

                        <h2 className="mt-6 text-lg leading-6 font-medium text-gray-900">Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
                        <p className="mt-2 text-base text-gray-500">Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>

                        <p className="mt-2 text-base text-gray-500">Quelle: <a href="https://www.e-recht24.de">eRecht24</a></p>


                    </div>
                </div>
            </div>
        </div>
            </Layout>
        </>
    )
}
